import React from "react"
import ReactLoading from "react-loading"
import styles from "../components/preloader.module.scss"

const Preloader = (props) => (
    <div className={`${styles.preloader} preloader`}>
      <ReactLoading 
        type={props.type || `none`} 
        color={props.color || `white`} 
        height={props.height || `50%`} 
        width={props.width || `50%`} />
    </div>
)

export default Preloader